module.exports = [{
      plugin: require('/home/brais/Projects/brais.dev/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":590}},{"resolve":"gatsby-remark-responsive-iframe","options":{"wrapperStyle":"margin-bottom: 1.0725rem"}},"gatsby-remark-prismjs","gatsby-remark-copy-linked-files","gatsby-remark-smartypants",{"resolve":"gatsby-remark-external-links","options":{"target":"_blank","rel":"nofollow"}},"gatsby-remark-autolink-headers"]},
    },{
      plugin: require('/home/brais/Projects/brais.dev/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/brais/Projects/brais.dev/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-144586274-1"},
    },{
      plugin: require('/home/brais/Projects/brais.dev/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/brais/Projects/brais.dev/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/home/brais/Projects/brais.dev/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
